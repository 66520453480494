.btn-login {
    background: linear-gradient(90deg, #00cfcf 14.69%, #1348EB 100%);
    border-radius: 50px;
}

.login-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 406px;
    max-width: 90%;
    
    /* Main/Dark Purple */
    
    background: #010608;
    border: 1px solid #00EBC1;
    box-shadow: 0px 0px 10px #00EBC1;
    border-radius: 10px;
}

.login-overlay {
    position: fixed;
    background: rgba(0, 0, 0, 0.85);
    inset: 0px;
    z-index: 20;
}

.login-modal-title {
    position: absolute;
    width: 600px;
}

.login-modal-container {
    display:flex;
    flex-direction: row;
}