.token-div {
    background: linear-gradient(90deg, #00cfcf 14.69%, #1348EB 100%);
}

.text-gradient-day {
    color: black;
    background: -webkit-linear-gradient(45deg, #00EBC1 14.69%, #1348EB 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-stroke: 3px transparent;
}
.text-gradient-month {
    color: black;
    background: white;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-stroke: 3px transparent;
}
#date-div::-webkit-scrollbar {
    width: 0px;
    height: 4px;
}

/* Track */
#date-div::-webkit-scrollbar-track {
    width: 30px;
    border-radius: 3px;
    background: #f1f1f1;
}

/* Handle */
#date-div::-webkit-scrollbar-thumb {
    width: 15px;
    background: #FF685B;
}


@media screen and (max-height: 700px) {
    .support-hidden-vertical-account {
        display: none !important;
    }    
}