@tailwind base;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Kulim+Park:wght@300;400;700&family=Poppins:wght@300;400;500;700&display=swap');

html, body {
  min-height: 100vh;

  display: flex;
  flex-direction: column;
}

body {
  background: url(assets/img/bg-mobile.png);
  background-repeat: no-repeat;

  color: white;
  font-family: 'Poppins', sans-serif;
}

body > * {
  flex-shrink: 0;
}

@media screen and (min-width: 600px) {
  body {
    background: url(assets/img/bg.png);
    background-size: cover;
    background-repeat: no-repeat;
  }
}

#root {
  min-height: 100vh
}

.transaction-toast {
  background-color: black !important;
  box-shadow: 0px 0px 10px #00EBC1 !important;
}